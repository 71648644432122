import { apiFetch, EMethods, format, IApiFetchProps } from "@private/isi-core";
import environment from '../../environments'
import ApiRoutes from "../ApiRoutes";

export const loggedApiRequest = (props: IApiFetchProps) => {
  return apiFetch({ ...props, timeout: 10000 })
}

export interface IApiRequest {
  baseUri?: string
}

interface IGetMeRequest extends IApiRequest{
  ident: string
}
export const getMe = ({ ident, baseUri }: IGetMeRequest) => {
  const httpBase = baseUri ?? environment.ApiBaseUrl
  return loggedApiRequest({
    method: EMethods.GET,
    route: format(ApiRoutes.ME, httpBase, ident),
  })
}