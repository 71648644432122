import React from 'react'
import "./PlayingAlbum.css"
import { Album, PlaybackState, SimplifiedAlbum } from "@spotify/web-api-ts-sdk";
import { PlayingItem, useSdk } from "../hooks/useSdk";
import { CiPlay1, CiPause1, CiVolume, CiVolumeHigh } from "react-icons/ci";
import useAsyncEffect from "../hooks/useAsyncEffect";


interface IPlayingAlbum {
  playbackState?: PlaybackState
}


const ICON_SIZE = 100;

export const PlayingAlbum = ({ playbackState: ps }: IPlayingAlbum) => {
  const [ playbackState, setPlaybackState ] = React.useState<PlaybackState | undefined>(ps)
  const [ volume, setVolume ] = React.useState(playbackState?.device?.volume_percent ?? 50)

  const activeItem = playbackState?.item as unknown as PlayingItem
  const { sdk } = useSdk()
  // React.useEffect(() => {
  //   if (!album) return
  //
  //   const play = async () => {
  //     const devices = await sdk.player.getAvailableDevices()
  //     const jarnoDevice = devices.devices.find(d => d.name.indexOf("KiddyBox") >= 0)
  //     if (!jarnoDevice?.id) return
  //
  //     await sdk.player.startResumePlayback(jarnoDevice.id, album.uri)
  //   }
  //
  //   play().then()
  //
  // }, [ album ])

  const pause = async () => {
    if (!playbackState?.device.id) return
    await sdk.player.pausePlayback(playbackState?.device.id)

    setTimeout(() => {
      sdk.player.getPlaybackState().then(setPlaybackState)
    }, 1000)
  }

  const play = async () => {
    if (!playbackState?.device.id) return
    await sdk.player.startResumePlayback(playbackState?.device.id)

    setTimeout(() => {
      sdk.player.getPlaybackState().then(setPlaybackState)
    }, 1000)
  }

  const changeVolume = async (change: number) => {
    if (!playbackState?.device.id) return
    let targetVol = volume + change;
    if (targetVol < 0) targetVol = 0
    if (targetVol > 100) targetVol = 100

    setVolume(targetVol)
  }

  const updateVolume = async () => {
    if (!playbackState) return
    await sdk.player.setPlaybackVolume(volume)
    setTimeout(() => {
      sdk.player.getPlaybackState().then(setPlaybackState)
    }, 1000)
  }

  useAsyncEffect(updateVolume, [ volume ])

  return (
    <div className="full_center">
      { playbackState && activeItem && activeItem.album && (
        <>
          <img src={ activeItem.album.images[0].url } alt={ activeItem.album.name } />
          <p>{ activeItem.name }</p>
          <div className="controls">
            <CiVolume className="btn_icon_pause" size={ ICON_SIZE } onClick={ () => changeVolume(-5) } />
            { playbackState.is_playing && <CiPause1 className="btn_icon_pause" size={ ICON_SIZE } onClick={ pause } /> }
            { !playbackState.is_playing && <CiPlay1 className="btn_icon_pause" size={ ICON_SIZE } onClick={ play } /> }
            <CiVolumeHigh className="btn_icon_pause" size={ ICON_SIZE } onClick={ () => changeVolume(5) } />
          </div>
        </>
      ) }
    </div>
  )
}