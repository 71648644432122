import React from "react";
import * as API from "../api/routes";

export const useApi = () => {

  const handleApiResponse = (response: any) => {

    return response
  }

  const apiMethods = React.useMemo(() => {
    const getMe = async (ident :string, baseUri?: string) => handleApiResponse(await API.getMe({ ident, baseUri }))

    return {
      getMe
    }
  }, [])


  return apiMethods
}